import './App.css';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loader from './components/Loader';


// Lazy load components
const DashBoard = lazy(() => import('./components/DashBoard'));
const DownlineUser = lazy(() => import('./components/DownlineUser'));
const KycReport = lazy(() => import('./components/KycReport'));
const MastAdminNav = lazy(() => import('./components/MastAdminNav'));
const MatmReport = lazy(() => import('./components/MatmReport'));
const AepsReport = lazy(() => import('./components/AepsReport'));
const PanReport = lazy(() => import('./components/PanReport'));
const InsuranceReport = lazy(() => import('./components/InsuranceReport'));
const EarningReport = lazy(() => import('./components/EarningReport'));
const RechargeReport = lazy(() => import('./components/RechargeReport'));
const BbpsReport = lazy(() => import('./components/BbpsReport'));
const UserCommission = lazy(() => import('./components/UserCommission'));
const NewNav = lazy(() => import('./components/NewNav'));
const ChangePassword = lazy(() => import('./components/ChangePassword'));
const ViewProfile = lazy(() => import('./components/ViewProfile'));
const BbpsUserComm = lazy(() => import('./components/BbpsUserComm'));
const TransactionReport = lazy(() => import('./components/TransactionReport'));
const AadharpayReport = lazy(() => import('./components/AadharpayReport'));
// const UserTxnActivity = lazy(() => import('./components/UserTxnActivity'));
const CouponReqReport = lazy(() => import('./components/CouponReqReport'));
const ApiUtiAgentReport = lazy(() => import('./components/ApiUtiAgentReport'));
const DmtReport = lazy(() => import('./components/DmtReport'));


const EmployeeDashboard = lazy(() => import('./Employee Component/EmployeeDashboard'));
const EmployeeNavbar = lazy(() => import('./Employee Component/EmployeeNavbar'));
const AssignedUserActivity = lazy(() => import('./Employee Component/AssignedUserActivity'));
const ViewAssignedTask = lazy(() => import('./Employee Component/ViewAssignedTask'));
const ViewInactiveTask = lazy(() => import('./Employee Component/ViewInactiveTask'));

const LazyToastContainer = lazy(() => import('./LazyToastContainer'));


function App() {


   return (
      <BrowserRouter basename='/'>
         <Suspense fallback={<div><Loader /></div>}>
            <LazyToastContainer />

            <Routes>
               <Route path='/mastadminnav' element={<MastAdminNav />} />
               <Route path='/dashboard' element={<DashBoard />} />
               <Route path='/downlineuser' element={<DownlineUser />} />
               <Route path='/kycreport' element={<KycReport />} />
               <Route path='/matmreport' element={<MatmReport />} />
               <Route path='/aepsreport' element={<AepsReport />} />
               <Route path='/panreport' element={<PanReport />} />
               <Route path='/insurancereport' element={<InsuranceReport />} />
               <Route path='/earningreport' element={<EarningReport />} />
               <Route path='/rechargereport' element={<RechargeReport />} />
               <Route path='/bbpsreport' element={<BbpsReport />} />
               <Route path='/newnav' element={<NewNav />} />
               <Route path='/usercommission' element={<UserCommission />} />
               <Route path='/changepassword' element={<ChangePassword />} />
               <Route path='/viewProfile' element={<ViewProfile />} />
               <Route path='/bbpsusercomm' element={<BbpsUserComm />} />
               <Route path='/transactionreport' element={<TransactionReport />} />
               <Route path='/aadharpayreport' element={<AadharpayReport />} />
               {/* <Route path='/usertxnactivity' element={<UserTxnActivity />} /> */}
               <Route path='/dmtreport' element={<DmtReport />} />

               {/* API USER */}
               <Route path='/couponreqreport' element={<CouponReqReport />} />
               <Route path='/apiutiagentreport' element={<ApiUtiAgentReport />} />


               {/* For Employee */}

               <Route path='/employeedashboard' element={<EmployeeDashboard />} />
               <Route path='/employeeNavbar' element={<EmployeeNavbar />} />
               <Route path='/assigneduseractivity' element={<AssignedUserActivity />} />
               <Route path='/viewassignedtask' element={<ViewAssignedTask />} />
               <Route path='/viewinactivetask' element={<ViewInactiveTask />} />

            </Routes>
         </Suspense>
      </BrowserRouter>
   );
}

export default App;
